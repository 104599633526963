import React from "react";
import SEO from "../components/Seo/seo"
import styled from 'styled-components';
import { theme } from '../utils/theme';
import { responsive } from '../utils/responsive';
import Layout from "../components/Layout/Layout";
import { Link } from "gatsby";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FooterComponent from "../components/Home/Footer/FooterComponent";
import { StyledMainContainer, StyledLeftContainer, StyledLeftContentContainer, StyledRightContainer, StyledImageWrapper, StyledDescription, StyledButtonWrapper, StyledTitle } from './head';


// ---- Images ----

import ControllersImage1 from '../components/imagesStock/Controllers_Img/ControllersImage1';
import ControllersImage2 from '../components/imagesStock/Controllers_Img/ControllersImage2';
import ControllersImage3 from '../components/imagesStock/Controllers_Img/ControllersImage3';
import ControllersImage4 from '../components/imagesStock/Controllers_Img/ControllersImage4';
import ControllersImage5 from "../components/imagesStock/Controllers_Img/ControllersImage5";


// ---- List Styles ----

export const StyledMainVerticalContainer = styled(StyledMainContainer)`
  
    ${theme.media.cloud} {
       flex-direction: column-reverse;
        height: 100%;

    }
`
export const StyledRightVerticalContainer = styled(StyledRightContainer)`
padding:  5vh;
`
export const StyledVerticalImageWrapper = styled(StyledImageWrapper)`
    margin: 0 15vw;

img {
    height: 80%;
}

${theme.media.cloud} {
    margin: 0 5vw;
}
`
const StyledControllersTitle = styled(StyledTitle)`
font-size: 3.5rem;
`


const ControllersSubpage = (props) => {

    return (
        <Layout>
            <SEO title="Controllers" />

            <StyledMainVerticalContainer>

                <StyledLeftContainer>

                    <StyledLeftContentContainer>

                        <StyledControllersTitle>{props.uri.replace(/[/]/g, "")}</StyledControllersTitle>

                        <StyledDescription>
                            <h1>Knob&nbsp;&&nbsp;Sensor</h1>

                            <p>
                                A handmade brass dimmer knob and an electronic proximity sensor, responsible for turning the lamp ON and OFF, are positioned below.<br /><br />

                                The sensor is discreetly housed in a camera lens body. These are the sole controllers equipped with the lamp.
                            </p>

                        </StyledDescription>

                        <StyledButtonWrapper>
                            <Link title="This is a link to home subpage" as="a" to="/" aria-label="home"><h1>Back</h1></Link>
                        </StyledButtonWrapper>

                        <FooterComponent />

                    </StyledLeftContentContainer>

                </StyledLeftContainer>


                <StyledRightVerticalContainer>

                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true}
                        keyBoardControl={true}
                        removeArrowOnDeviceType={["tabletPro", "mobile"]}
                    >

                        <StyledVerticalImageWrapper>
                            <ControllersImage1 />
                        </StyledVerticalImageWrapper>

                        <StyledVerticalImageWrapper>
                            <ControllersImage2 />
                        </StyledVerticalImageWrapper>

                        <StyledVerticalImageWrapper>
                            <ControllersImage3 />
                        </StyledVerticalImageWrapper>

                        <StyledVerticalImageWrapper>
                            <ControllersImage4 />
                        </StyledVerticalImageWrapper>

                        <StyledVerticalImageWrapper>
                            <ControllersImage5 />
                        </StyledVerticalImageWrapper>

                    </Carousel>

                </StyledRightVerticalContainer>

            </StyledMainVerticalContainer>

        </Layout >
    )
}

export default ControllersSubpage;