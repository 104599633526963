import React from "react";
import SEO from "../components/Seo/seo";
import styled from 'styled-components';
import { responsive } from '../utils/responsive';
import Layout from "../components/Layout/Layout";
import { Link } from "gatsby";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { StyledLeftContainer, StyledDescription, StyledButtonWrapper, StyledTitle, StyledLeftContentContainer } from './head';
import { StyledMainVerticalContainer, StyledRightVerticalContainer, StyledVerticalImageWrapper } from './controllers';
import FooterComponent from "../components/Home/Footer/FooterComponent";


// ---- Images ----

import CoreImage1 from '../components/imagesStock/Core/CoreImage1';
import CoreImage2 from '../components/imagesStock/Core/CoreImage2';
import CoreImage3 from '../components/imagesStock/Core/CoreImage3';
import CoreImage4 from '../components/imagesStock/Core/CoreImage4';


// ---- List Styles ----

const StyledCoreImageWrapper = styled(StyledVerticalImageWrapper)`
    margin: 0 18vw;
`


const CoreSubpage = props => {

    return (
        <Layout>
            <SEO title="Core" />

            <StyledMainVerticalContainer>

                <StyledLeftContainer>

                    <StyledLeftContentContainer>

                        <StyledTitle>{props.uri.replace(/[/]/g, "")}</StyledTitle>

                        <StyledDescription>
                            <h1>Source of Light</h1>

                            <p>
                                The core is composed of durable aluminum elements, with the top surface finished with a brass sheet secured by brass rivets.<br /><br />

                                Heat sinks, along with LED modules attached to them, are positioned below. The modules are housed in ceramic holders and connected to the power supply via high-class Arcol resistors using heat-resistant silicone wires. <br /><br />

                                To enhance heat dissipation, CPU thermal paste has been applied between the modules and the heat sinks. Handcrafted frosted glass covers not only provide additional protection but also soften the emitted light.
                            </p>

                        </StyledDescription>

                        <StyledButtonWrapper>
                            <Link title="This is a link to head subpage" as="a" to="/head" aria-label="head"><h1>Back</h1></Link>
                            <Link title="This is a link to home page" as="a" to="/" aria-label="home"><h1>Home</h1></Link>
                        </StyledButtonWrapper>

                        <FooterComponent />

                    </StyledLeftContentContainer>

                </StyledLeftContainer>

                <StyledRightVerticalContainer>

                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true}
                        keyBoardControl={true}
                        removeArrowOnDeviceType={["tabletPro", "mobile"]}
                    >

                        <StyledCoreImageWrapper>
                            <CoreImage1 />
                        </StyledCoreImageWrapper>

                        <StyledCoreImageWrapper>
                            <CoreImage2 />
                        </StyledCoreImageWrapper>

                        <StyledCoreImageWrapper>
                            <CoreImage3 />
                        </StyledCoreImageWrapper>

                        <StyledCoreImageWrapper>
                            <CoreImage4 />
                        </StyledCoreImageWrapper>

                    </Carousel>

                </StyledRightVerticalContainer>

            </StyledMainVerticalContainer>
        </Layout>
    )
}

export default CoreSubpage;